import React, { useEffect, useState } from "react";
import axios from "axios";
import Header from "./components/Header";
import MenuButton from "./components/MenuButton";
import FoodItem from "./components/FoodItem";
import BackToTopButton from "./components/BackToTopButton";

import "./styles/App.scss";
import InfoIcon from "./components/InfoIcon";

const App = () => {
  const [menuData, setMenuData] = useState([]);
  const [categories, setCategories] = useState([]);

  const processCategoriesData = (data) => {
    const categories = data.slice(1).map((item) => item[0]);
    setCategories(categories);
  };

  const processMenuData = (data) => {
    const menuItems = data.slice(1).reduce((acc, item) => {
      const [category, title, details, weight, price, details_after_price] =
        item;
      return {
        ...acc,
        [category]: [
          ...(acc[category] || []),
          {
            title,
            details,
            weight,
            price,
            details_after_price
          }
        ]
      };
    }, {});
    setMenuData(menuItems);
  };

  useEffect(() => {
    const fetchData = async () => {
      const spreadsheetId = "1YWOpkShGKsH3zu5vcmfM2K2gGrenVIyOP7RnHqBI_SM";
      const apiKey = "AIzaSyB03uh3XnvPPOJ8DDlYAlgI4jT8WOys-uk";
      const categoriesRange = "categories!A:B"; // Assuming categories are in a sheet named 'Categories'
      const menuItemsRange = "menu!A:F"; // Assuming menu items are in a sheet named 'MenuItems'

      const categoriesUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${categoriesRange}?key=${apiKey}`;
      const menuItemsUrl = `https://sheets.googleapis.com/v4/spreadsheets/${spreadsheetId}/values/${menuItemsRange}?key=${apiKey}`;

      try {
        const [categoriesResponse, menuItemsResponse] = await Promise.all([
          axios.get(categoriesUrl),
          axios.get(menuItemsUrl)
        ]);

        const categoriesData = categoriesResponse.data.values;
        const menuItemsData = menuItemsResponse.data.values;

        processCategoriesData(categoriesData);
        processMenuData(menuItemsData);
      } catch (error) {
        console.error("Error fetching data from Google Sheets", error);
      }
    };

    fetchData();
  }, []);

  return (
    <div className="App">
      <Header />
      <div className="menu-buttons">
        {categories.map(
          (category) =>
            menuData[category]?.length && (
              <MenuButton key={category} label={category} scrollTo={category} />
            )
        )}
      </div>
      {categories.map(
        (category) =>
          category &&
          menuData[category]?.length && (
            <div className="food-section" id={category} key={category}>
              <h2 className="food-section-category">{category}</h2>
              {menuData[category].map((item, index) => (
                <FoodItem key={index} {...item} />
              ))}
            </div>
          )
      )}
      <BackToTopButton />
      <InfoIcon />
    </div>
  );
};

export default App;

import React from "react";

const MenuButton = ({ label, scrollTo }) => {
  return (
    <button
      className="menu-button"
      onClick={() =>
        document.getElementById(scrollTo).scrollIntoView({ behavior: "smooth" })
      }
    >
      {label}
    </button>
  );
};

export default MenuButton;

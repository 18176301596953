import React from "react";

const FoodItem = ({ title, weight, details, price, details_after_price }) => {
  return (
    <div className="food-item">
      <div className="item-info">
        <h1>{title}</h1>
        <h2>{details}</h2>
        <h3>{weight}</h3>
      </div>
      <div className="item-price">
        <h2>
          {price} <span>{details_after_price}</span>
        </h2>
      </div>
    </div>
  );
};

export default FoodItem;

import React, { useState, useEffect, useRef } from "react";
import "./styles.scss";

const InfoIcon = () => {
  const [isDialogVisible, setIsDialogVisible] = useState(false);
  const [isFadingOut, setIsFadingOut] = useState(false);
  const dialogRef = useRef(null);

  const toggleDialog = () => {
    if (isDialogVisible) {
      setIsFadingOut(true);
      setTimeout(() => {
        setIsDialogVisible(false);
        setIsFadingOut(false);
      }, 300); // Duration of the fade-out animation
    } else {
      setIsDialogVisible(true);
    }
  };

  const handleClickOutside = (event) => {
    if (dialogRef.current && !dialogRef.current.contains(event.target)) {
      toggleDialog();
    }
  };

  useEffect(() => {
    if (isDialogVisible) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside, isDialogVisible]);

  return (
    <div className="info-icon-container">
      <div className="info-icon" onClick={toggleDialog}>
        <svg
          width="42px"
          height="42px"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12ZM12 17.75C12.4142 17.75 12.75 17.4142 12.75 17V11C12.75 10.5858 12.4142 10.25 12 10.25C11.5858 10.25 11.25 10.5858 11.25 11V17C11.25 17.4142 11.5858 17.75 12 17.75ZM12 7C12.5523 7 13 7.44772 13 8C13 8.55228 12.5523 9 12 9C11.4477 9 11 8.55228 11 8C11 7.44772 11.4477 7 12 7Z"
            fill="#ee4036"
          />
        </svg>
      </div>
      {isDialogVisible && (
        <div
          className={`info-dialog ${isFadingOut ? "fade-out" : ""}`}
          ref={dialogRef}
        >
          <h2>Alergeni: </h2>

          <ul>
            <li>1) GLUTEN SI PRODUSE DERIVATE</li>
            <li>2) CRUSTACEE SI PRODUSE DERIVATE</li>
            <li>3) OUA SI PRODUSE DERIVATE</li>
            <li>4) PESTE SI PRODUSE DERIVATE</li>
            <li>5) ARAHIDE SI PRODUSE DERIVATE</li>
            <li>6) SOIA SI PRODUSE DERIVATE</li>
            <li>7) LAPTE SI PRODUSE DERIVATE</li>
            <li>
              8) FRUCTE CU COAJA (MIGDALE, NUCI, ALUNE) SI PRODUSE DERIVATE
            </li>
            <li>9) TELINA SI PRODUSE DERIVATE</li>
            <li>10) MUSTAR SI PRODUSE DERIVATE</li>
            <li>11) SEMINTE DE SUSAN SI PRODUSE DERIVATE</li>
            <li>12) DIOXID DE SULF SI SULFITI</li>
            <li>13) LUPIN SI PRODUSE DERIVATE</li>
            <li>14) MOLUSTE SI PRODUSE DERIVATE</li>
            <li>*) DIN PRODUS CONGELAT</li>
          </ul>

          {/* <button onClick={toggleDialog}>Close</button> */}
        </div>
      )}
    </div>
  );
};

export default InfoIcon;

import React from "react";

const Header = () => {
  return (
    <header className="header">
      <div className="header-text">
        <h1>MeatConcept</h1>
        <h2>SteakHouse</h2>
      </div>
      <div className="header-image"></div>
    </header>
  );
};

export default Header;
